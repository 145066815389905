<template>
  <esmp-link :to="url" class="faq-article-item">
    <div class="faq-article-item__image">
      <div
        v-if="!isNoImage"
        class="faq-article-item__image-bg"
        :style="`background-image: url(${image})`"
      />
      <picture>
        <img
          :src="image"
          :alt="title"
          ref="image"
          @error="onImageLoadError"
        >
      </picture>
    </div>

    <div v-if="additionalInfo" class="faq-article-item__additional-info">
      {{ additionalInfo }}
    </div>

    <h3 class="faq-article-item__title">
      {{ title }}
    </h3>
  </esmp-link>
</template>

<script>
// TODO: сделать глобальным (переименовать) -- используется на главной, в новостях и FAQ

import { ARTICLE_ITEM_IMAGE_PLACEHOLDER } from '@/constants/global';
import { mapGetters } from 'vuex';

export default {
  name: 'FaqArticleItem',

  props: {
    imageUrl: {
      type: String,
      default: ARTICLE_ITEM_IMAGE_PLACEHOLDER,
    },

    title: {
      type: String,
      default: 'Статья без названия',
    },

    additionalInfo: {
      type: String,
      default: undefined,
    },

    url: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('system', ['faqArticleImageUrl']),
    isNoImage() {
      return this.imageUrl === ARTICLE_ITEM_IMAGE_PLACEHOLDER;
    },
    isFaqPage() {
      return this.$route.name === 'FAQ' || this.$route.name === 'FAQArticle';
    },
    image() {
      if (!this.isFaqPage && this.imageUrl) {
        return this.imageUrl || this.faqArticleImageUrl;
      }
      return this.imageUrl;
    },
  },

  methods: {
    onImageLoadError() {
      this.$refs.image.style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
.faq-article-item {
  $parentClass: &;

  position: relative;
  cursor: pointer;

  &__image {
    position: relative;
    height: 180px;
    margin-bottom: 16px;
    border-radius: $base-border-radius * 2;
    overflow: hidden;
    display: block;

    picture {
      position: relative;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    @include for-size(phone-portrait-down) {
      margin-bottom: 8px;
    }
  }

  &__image-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    background-position: center;
    background-size: cover;
    opacity: 0.25;
    z-index: 1;
  }

  &__additional-info {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
    transition: color $base-animation-time $base-animation-function;
  }

  &__title {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    color: $color-black;
    transition: color $base-animation-time $base-animation-function;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -$base-border-radius;
    left: -$base-border-radius;
    width: calc(100% + #{$base-border-radius * 2});
    height: calc(100% + #{$base-border-radius * 2});
    background: $color-white;
    border-radius: $base-border-radius * 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity $base-animation-time $base-animation-function;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    #{$parentClass}__additional-info,
    #{$parentClass}__title {
      color: $primary-color;
    }
  }
}
</style>
