<template>
  <div class="faq-category-list tile-grid">
    <faq-category-item
      v-for="category in categories"
      :key="`category_${category.id}`"
      :title="category.title"
      :url="category.url"
      :image-url="category.faqCategoryImageUrl"
    />
  </div>
</template>

<script>
import FaqCategoryItem from './FaqCategoryItem.vue';

export default {
  name: 'FaqCategoryList',
  components: { FaqCategoryItem },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.faq-category-list {
  margin-bottom: 24px;
  @include for-size(phone-portrait-down) {
    &.tile-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      column-gap: 16px;
      row-gap: 16px
    }
  }
}
</style>
