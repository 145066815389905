/**
 * Создание структуры элемента хлебных крошек.
 * @param label {string} - отображаемый текст
 * @param link {string} - ссылка
 * @return {{link, label}} - структура хлебной крошки
 */
export default (label, link) => ({
  label,
  link,
});
