export const NEWS_PER_PAGE = 10;

export const NEWS_SORT_TYPES = {
  newup: {
    id: 'newup',
    name: 'по дате: новые сверху',
  },
  newdown: {
    id: 'newdown',
    name: 'по дате: новые снизу',
  },
};
