<template>
  <div class="faq-article-list">
    <div class="faq-article-list__items">
      <esmp-loader v-if="loading" fix />

      <div class="tile-grid">
        <faq-article-item
          v-for="article in localArticles"
          :key="`article_${article.id}`"
          :image-url="article.imageUrl || article.faqArticleImageUrl"
          :title="article.title"
          :url="article.url"
        />
      </div>
    </div>

    <div v-if="pageCount > 1" class="faq-article-list__pagination">
      <esmp-pagination-adaptive
        :page-count="pageCount"
        :current-page.sync="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { FAQ_ARTICLES_COUNT_PER_PAGE } from '@/constants/faq-page';
import Hub from '@/plugins/event-hub';
import FaqArticleItem from './FaqArticleItem.vue';

export default {
  name: 'FaqArticleList',

  components: { FaqArticleItem },

  props: {
    articles: {
      type: Array,
      default: () => [],
    },

    pageCount: {
      type: Number,
      default: 1,
    },

    categoryId: {
      type: String,
      default: '0',
    },

    activeSortType: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      currentPage: 1,
      selfLoadedArticles: null,
      loading: false,
    };
  },

  computed: {
    localArticles() {
      return this.selfLoadedArticles || this.articles;
    },
  },

  created() {
    Hub.$on('reset-faq-category-articles-data', this.resetArticlesData);
  },

  destroyed() {
    Hub.$off('reset-faq-category-articles-data', this.resetArticlesData);
  },

  watch: {
    currentPage(value) {
      this.getPagedArticles(value);
    },
  },

  methods: {
    async getPagedArticles(page) {
      this.loading = true;

      const { content: articles } = await this.$API.faq.getCategoryArticles(
        this.categoryId,
        page - 1,
        FAQ_ARTICLES_COUNT_PER_PAGE,
        this.activeSortType?.code,
      );

      // TODO: вынести маппинг в сервис api Faq
      this.selfLoadedArticles = articles.map((article) => ({
        ...article,
        url: `/faq/article/${article.id}`,
        // TODO: добавить поле для заглавного изображения статьи - imageUrl
      }));

      this.loading = false;
    },

    resetArticlesData() {
      this.currentPage = 1;
      this.selfLoadedArticles = null;
    },
  },
};
</script>

<style lang="scss">
.faq-article-list {
  margin-bottom: 28px;

  &__items {
    position: relative;
  }

  &__pagination {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  @include for-size(phone-portrait-down) {
    .tile-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      column-gap: 16px;
      row-gap: 16px
    }
  }
}
</style>
