import { mapActions } from 'vuex';
import breadcrumb from '@/utils/breadcrumb';

const rootCategoryBreadcrumb = breadcrumb('База знаний', '/faq');

export default {
  methods: {
    ...mapActions('breadcrumbs', ['setBreadcrumbs', 'clearBreadcrumbs']),

    /**
     * Построить дерево хлебных крошек для родителей категории
     * @param categoryId {number} - id активной категории
     * @param additionalBreadcrumbs {array} - массив хлебных крошек,
     *    которые можно вставить в конец формируемого массива хлебных крошек
     * @return {Promise<void>}
     */
    async setBreadcrumbsFromParents(categoryId, additionalBreadcrumbs = []) {
      if (categoryId !== 0) {
        const parents = await this.$API.faq.getParentsTree(categoryId);
        const breadcrumbs = parents
          ? parents.reverse().map(
            (item) => (item.parentCategoryId === null
              ? rootCategoryBreadcrumb
              : breadcrumb(item.title ? item.title : 'База знаний', `/faq/category/${item.id}`)),
          )
          : [];

        this.setBreadcrumbs([...breadcrumbs, ...additionalBreadcrumbs]);
      } else {
        this.setDefaultBreadcrumbs();
      }

      return Promise.resolve();
    },

    setDefaultBreadcrumbs() {
      this.setBreadcrumbs([rootCategoryBreadcrumb]);
    },
  },
};
