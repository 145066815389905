<template>
  <esmp-link :to="url" class="faq-category-item">
    <div class="faq-category-item__header">
      <h3 class="faq-category-item__title">
        {{ title }}
      </h3>

      <esmp-tag
        v-if="!isFaqPage"
        class="faq-category-item__icon"
        :icon="icon"
        color="purple"
      />
      <esmp-tag
        v-else
        color="purple"
        shape="circular"
      >
        <img
          class="faq-category-item__image"
          :src="imageUrl || faqCategoryImageUrl"
          :alt="title"
        >
      </esmp-tag>
    </div>

    <p
      v-if="articlesCountCaption > 0"
      class="faq-category-item__articles-count"
    >
      {{ articlesCountCaption }}
    </p>
  </esmp-link>
</template>

<script>
import { mapGetters } from 'vuex';
import pluralization from '@/utils/pluralization';

export default {
  name: 'FaqCategoryItem',

  props: {
    icon: {
      type: String,
      default: 'portfolio',
    },

    imageUrl: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      required: true,
      default: 'Без имени',
    },

    articlesCount: {
      type: Number,
      default: 0,
    },

    url: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('system', ['faqCategoryImageUrl']),
    articlesCountCaption() {
      return `${this.articlesCount} ${pluralization(
        this.articlesCount,
        ['статья', 'статьи', 'статей'],
      )}`;
    },
    isFaqPage() {
      return this.$route.name === 'FAQ' || this.$route.name === 'FAQCategory';
    },
  },
};
</script>

<style lang="scss">
.faq-category-item {
  border-radius: $base-border-radius * 2;
  padding: 20px;
  background: $color-white;
  cursor: pointer;

  @include for-size(phone-portrait-down) {
    padding: 16px;
    min-height: 152px;
    &__icon {
      width: 48px;
      height: 48px;
    }
  }

  &__image {
    width: 24px;
    display: block;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 34px;
    @include for-size(phone-portrait-down) {
      flex-direction: column-reverse;
      margin-bottom: 16px;
    }
  }

  &__title {
    flex-grow: 1;
    padding-top: 4px;
    padding-right: 10px;
    color: $color-text;
    font-weight: 700;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      padding: 0;
      margin-top: 12px;
    }
  }

  &__articles-count {
    color: $color-grayscale-60;
  }

  &:hover {
    transition: box-shadow $base-animation-time
      $base-animation-function;
    box-shadow: $base-shadow-xl;
  }
}
</style>
