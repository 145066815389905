<template>
  <div class="page page__news news">
    <div class="news__content">
      <esmp-tabs v-model="localNewsCat">
        <template #extra>
          <div class="news__sort">
            <esmp-button
              class="news__sort-btn"
              view="function"
              icon="sort"
              icon-position="left"
            >
              Сортировка
            </esmp-button>
            <div class="news__sort-list">
              <a
                :class="[
                  'news__sort-item',
                  { 'news__sort-item--active': activeSortType.id === type.id },
                ]"
                v-for="type in NEWS_SORT_TYPES"
                :key="type.id"
                @click="activeSortType = type"
              >
                {{ type.name }}
              </a>
            </div>
          </div>
        </template>
        <esmp-tab-pane
          label="Активные новости"
          name="actual"
        />
        <esmp-tab-pane
          label="Архивные новости"
          name="archive"
        />
      </esmp-tabs>
      <div v-if="mappedNews && mappedNews.length" class="news__list tile-grid">
        <faq-article-item
          v-for="article in mappedNews"
          :key="article.id"
          :image-url="article.mainImageUrl"
          :title="article.title"
          :additional-info="article.additionalInfo"
          :url="article.url"
        />
      </div>
      <div v-else>
        Нет записей
      </div>
    </div>
    <esmp-pagination-adaptive
      class="news__pagination"
      :page-count="pageCount"
      :current-page.sync="localCurrentPage"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { FaqArticleItem } from '@/components/faq';
import { NEWS_PER_PAGE, NEWS_SORT_TYPES } from '@/constants/news';

export default {
  name: 'News',
  components: {
    FaqArticleItem,
  },
  data() {
    return {
      activeSortType: NEWS_SORT_TYPES.newup,
    };
  },
  computed: {
    ...mapGetters('news', ['mappedNews']),
    ...mapState('news', ['newsCat', 'currentPage', 'pageCount']),
    localNewsCat: {
      get() {
        return this.newsCat;
      },
      set(newValue) {
        this.setNewsCat(newValue);
      },
    },
    localCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(newValue) {
        this.setCurrentPage(newValue);
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('news', ['getNews', 'setNewsCat', 'setCurrentPage']),
    getNewsList() {
      this.setLoading({ key: 'page', value: true });
      this.getNews({
        isPublic: true,
        state: this.localNewsCat,
        isMain: true,
        page: this.localCurrentPage - 1,
        size: NEWS_PER_PAGE,
        sortBy: this.activeSortType?.id,
      })
        .finally(() => this.setLoading({ key: 'page', value: false }));
    },
  },
  watch: {
    localNewsCat() {
      this.localCurrentPage = 1;
      this.getNewsList();
    },
    localCurrentPage() {
      this.getNewsList();
    },
    activeSortType() {
      this.getNewsList();
    },
  },
  created() {
    this.NEWS_SORT_TYPES = NEWS_SORT_TYPES;
    this.getNewsList();
  },
  beforeDestroy() {
    this.localCurrentPage = 1;
  },
};
</script>

<style lang="scss">
.news {
  $base-class: &;
  .tile-grid {
    grid-template-columns: 1fr 1fr 1fr;

    @include for-size(phone-portrait-down) {
      grid-template-columns: 1fr;
    }
  }

  &__list {
    margin-bottom: $gap;
  }

  &__pagination {
    justify-content: center;
  }

  &__sort {
    position: relative;
    height: 24px;
    line-height: 24px;
    z-index: 5;
    @include for-size(phone-landscape-down) {
      margin-right: 5px;
    }

    &:not(:last-child) {
      padding-right: 20px;
      border-right: 1px solid $color-black-op-25;
      margin-right: 20px;
    }

    &:hover {
      #{$base-class}__sort-list {
        visibility: visible;
        opacity: 1;
      }
      #{$base-class}__sort-btn {
        color: $primary-color-hover;
      }
    }
  }
  &__sort-btn {
    color: $color-black;
    transition: color $base-animation-time;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  &__sort-list {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    right: 0;
    min-width: 200px;
    background-color: $color-white;
    padding: 16px 20px;
    box-shadow: $base-shadow-l;
    border-radius: 8px;
    transition: visibility $base-animation-time, opacity $base-animation-time;
  }
  &__sort-item {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:hover,
    &--active {
      cursor: pointer;
      color: $primary-color-hover;
    }
  }
}
</style>
